import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import React from "react"
import Img from "gatsby-image"

import BackgroundImage from "gatsby-background-image"

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem;
  color: white;
`

const Logo = styled(Img)`
  width: 33%;
  @media(min-width: 768px) {
    width: 15%;
  }
`

const H1 = styled.h1`
  margin: 1rem 0;
`

const H3 = styled.h3``

const ButtonLink = styled(Link)`
  background-color: white;
  color: ${({ theme }) => theme.colors.primaryBlue};
  text-decoration: none;
  padding: 1rem 2rem;
  margin: 2rem 0 1rem 0;
  border-radius: 1rem;

`

const Header = ({ backgroundImageName, image, title, subtitle, cta }) => {
  const data = useStaticQuery(
    graphql`
      query {
        placeholderImage: allFile(
          filter: { relativeDirectory: { eq: "hero-backgrounds" } }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 2480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  const backgroundImage = data.placeholderImage.edges.find(
    ({ node }) => {
      return node.name === backgroundImageName
    }
  ).node
  
  if (!backgroundImage) {
    return null;
  }

  const logo = image ? <Logo durationFadeIn="0" fluid={image.fluid} /> : null;

  const button = cta ? (
    <ButtonLink to={cta.destination}><h4>{cta.title}</h4></ButtonLink>
  ) : null

  return (
    <BackgroundImage fluid={backgroundImage.childImageSharp.fluid}>
      <HeaderWrapper>
        {logo}
        <H1>{title}</H1>
        <H3>{subtitle}</H3>
        {button}
      </HeaderWrapper>
    </BackgroundImage>
  )
}
Header.defaultProps = {
  title: `Wheels On The Bus Consulting`,
  subtitle: ``,
  backgroundImageName: `home`,
  image: null
}

Header.propTypes = {
  backgroundImageName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.object,
  cta: PropTypes.shape({
    title: PropTypes.string,
    destination: PropTypes.string,
  }),
}

export default Header
