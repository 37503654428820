import styled from "styled-components"
import React from "react"
import PropTypes from "prop-types"

const MainWrapper = styled.div`
  padding: 2rem 2rem 4rem 2rem;
  @media(min-width:768px) {
    padding: 4vh 10vw; 
  }
`

const Main = ({ children }) => {
  return (
    <MainWrapper>
      {children}
    </MainWrapper>
  )
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
