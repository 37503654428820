import styled from "styled-components"
import React from "react"

const DividerWrapper = styled.div`
    width: 80vw;
    height: 1px;
    margin: 2rem auto;
    background-color: ${({ theme }) => theme.colors.gray};
`

const Divider = () => {
  return <DividerWrapper />
}

export default Divider;
